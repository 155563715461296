<template>
    <div class="terminal" @click="grantAccess" @keyup="grantAccess" tabindex="0">
      <h1 :class="{ 'access-granted': accessGranted }" class="header">{{ headerText }}</h1>
      <div v-for="(line, index) in displayedLines" :key="index" class="terminal-line" ref="lines">
        <span class="prefix">SERAPH.node $</span> {{ line }}
        <span v-if="index === currentLine" class="cursor"></span>
      </div>
    </div>
  </template>
  

<script>
export default {
  data() {
    return {
      headerText: '// NODE // SERAPH // ACCESS DENIED',
      allLines: [
            'Injetando codigos de acesso',
            'Inicializando interface de Luz',
            'Estabelecendo link com o Viajante',
            'Executando cálculos paracausais',
            'Escaneando anomalias da Escuridão',
            'Descriptografando pacotes de dados de engrama',
            'Mapeando linhas ley cósmicas',
            'Injetando Luz na rede neural',
            'Explorando portal temporal Vex',
            'Falsificando sinal do Fantasma',
            'Quebrando códigos de comando Cabal',
            'Baixando inteligência da Vanguarda',
            'Carregando protocolo de infusão de Luz',
            'Executando sequência de transmat',
            'Ganhando acesso ao Plano Ascendente',
            'Desativando glifos de Colmeia',
            'Terminando link com o Viajante',
            'Operação completa',
            'Executando simulações de combate',
            'Escaneando Guardiões próximos',
            'Analisando telemetria de armas',
            'Executando diagnósticos de casco de Fantasma',
            'Sincronizando com o banco de dados da Torre',
            'Protocolo aceito.',
            ],
      displayedLines: [],
      currentLine: 0,
      accessGranted: false,
      unlockMessage: this.isMobile() ? 'Toque na tela para desbloquear o acesso' : 'Pressione qualquer tela para desbloquear o acesso',
    };
  },
  mounted() {
  this.loadLines();
  window.addEventListener('keyup', this.grantAccess);
},
beforeDestroy() {
  window.removeEventListener('keyup', this.grantAccess);
},
methods: {
    
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    loadLines() {
  let i = 0;
  const interval = setInterval(() => {
    if (i >= this.allLines.length) {
      clearInterval(interval);
      if (!this.displayedLines.includes(this.unlockMessage)) {
        this.displayedLines.push(this.unlockMessage);
      }
      this.currentLine = i;
    } else {
      this.displayedLines.push(this.allLines[i]);
      this.currentLine = i;
      this.$nextTick(() => {
        const lines = this.$refs.lines;
        const lastLine = lines[lines.length - 1];
        lastLine.scrollIntoView({ behavior: 'smooth' });
      });
      i++;
    }
  }, 125); // Change lines every 125ms
    },
    grantAccess(event) {
    if (this.isMobile() || event instanceof KeyboardEvent) {
      this.accessGranted = true;
      this.headerText = '// NODE // SERAPH // ACCESS GRANTED';
      setTimeout(() => {
        window.location.href = 'https://discord.7thseraph.org';
      }, 2000); // Redirect after 2 seconds
    }
  },
},
};
</script>
  
<style>

body {
    background-color: black;
    color: lime; /* Green text color */
    font-family: 'Share Tech', sans-serif;
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: flex-start; /* Aligns items to the top */
    justify-content: flex-start; /* Aligns items to the left */
    text-align: left;
  }

  .cursor {
    border-bottom: 2px solid lime; /* Green underline */
    animation: blink 1s infinite;
  }

  .header {
    color: red; /* Red text color */
  }
  
  .access-granted {
    color: lime; /* Green text color */
  }
  
  .prefix {
    color: grey; /* Grey text color */
  }
  
  .terminal-line {
    color: lime; /* Green text color */
  }
  
  .cursor {
    border-bottom: 2px solid lime; /* Green underline */
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    50% { opacity: 0; }
  }
  
  .terminal {
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.2); /* Green glow around the terminal */
    padding: 20px;
    width: 100%; /* Makes the terminal cover the full width */
    height: 100vh; /* Makes the terminal cover the full height */
    box-sizing: border-box; /* Includes padding and border in element's total width and height */
  }
  
  
  /* CRT effect */
  .terminal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      0deg,
      rgba(0, 255, 0, 0.03),
      rgba(0, 255, 0, 0.03) 2px,
      transparent 2px,
      transparent 4px
    );
    pointer-events: none;
    z-index: 1;
  }
  
  .terminal > div {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    animation: typing 2s steps(40, end) forwards;
  }
  
  .prompt {
    color: white;
    animation: blink 1s infinite;
  }
  
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink {
    50% { opacity: 0; }
  }
  </style>