import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

import Home from "./views/home";
import Intro from "./views/intro";
import NotFound from "./views/not-found";
import "./style.css";

Vue.use(Router);
Vue.use(Meta);
export default new Router({
  mode: "history",
  routes: [
    {
      name: "Home",
      path: "/",
      component: Home,
    },
    {
      name: "Intro",
      path: "/intro",
      component: Intro,
    },
    {
      name: "404 - Not Found",
      path: "**",
      component: NotFound,
      fallback: true,
    },
  ],
});
