<template>
  <div class="home-container">
    <div class="home-header"></div>
    <div class="home-hero">
      <div class="home-hero1 heroContainer">
        <video
          loop="true"
          src="https://i.imgur.com/OcNaHVU.mp4"
          autoplay="true"
          playsinline="true"
          preload="auto"
          muted="true"
          class="home-video"
        ></video>
        <div class="home-container2">
          <h1 class="home-hero-heading">
            [SRPH] 7th Seraph
            <br/>
          </h1>
          <div>
            <h1 class="home-hero-tagline">// NODE // ERR // RECALL WARMIND</h1> 
          </div>
          <div class="home-btn-group">
            <a
              href="https://discord.7thseraph.org"
              target="_blank"
              rel="noreferrer noopener"
              class="home-hero-button1 buttonFilled"
              >Discord</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="home-features">
      <div class="featuresContainer">
        <div class="home-features1">
          <div class="home-container3">
            <span class="overline"
              ><span>Nos nascemos do LFG.</span><br
            /></span>
            <h2 class="home-features-heading heading2">
              Um clã sem fricção ou dor de cabeça.
            </h2>
            <span class="home-features-sub-heading bodyLarge"
              ><span
                ><span
                  ><span> <span v-html="raw51xh"></span></span></span
                ><span
                  ><span> <span v-html="rawz2bt"></span></span
                  ><span> <span v-html="raw95oc"></span></span></span></span
              ><span
                ><span
                  ><span> <span v-html="rawlttd"></span></span
                  ><span> <span v-html="rawjb8j"></span></span></span
                ><span
                  ><span> <span v-html="rawzxj8"></span></span
                  ><span> <span v-html="rawv8ej"></span></span></span></span
            ></span>
          </div>
          <div class="home-container4">
            <feature-card
              heading="// NODE / Atividade / INIT"
              subHeading="Com sistemas de agendamento e notificação, nos garantimos que você encontre outros querendo jogar"
            ></feature-card
            ><feature-card
              heading="// NODE / Inclusividade / INIT"
              subHeading="Acomodamos qualquer tipo de jogador, desde novatos até tryhards."
            ></feature-card
            ><feature-card
              heading="// NODE / Organização / INIT"
              subHeading="Utilizamos tecnologia de ponta para organizar e automatizar o funcionamento do nosso clã."
            ></feature-card
            ><feature-card
              heading="// NODE / G.A.M.E / INIT"
              subHeading="Utilizamos um sistema sofisticado para monitorar e separar jogadores ativos dos inativos para garantir que sempre tenha alguém para jogar com você!"
            ></feature-card>
          </div>
        </div>
      </div>
    </div>
    <div class="home-faq">
      <div class="faqContainer">
        <div class="home-faq1">
          <div class="home-container5">
            <span class="overline"><span>FAQ</span><br /></span
            ><span class="home-text23 bodyLarge"
              ><span>Aqui se encontram algumas das perguntas frequentes.</span
              ><br
            /></span>
          </div>
          <div class="home-container6">
            <app-question1
              answer="É só entrar no nosso Discord!"
              question="Como eu entro no 7th Seraph?"
            ></app-question1
            ><app-question1
              answer="Acomodamos todas as plataformas"
              question="Qual a plataforma do clã?"
            ></app-question1
            ><app-question1
              answer="Vontade de jogar destiny 2 em comunidade, acreditamos que clãs sem o aspecto de comunidade são comparaveis com o sistema de LFG do jogo."
              question="Quais os requerimentos para entrar no clã?"
            ></app-question1
            ><app-question1
              answer="Não. Porém seria recomendado ter ao menos a DLC mais recente"
              question="Preciso ter todas as DLC's do jogo?"
            ></app-question1
            ><app-question1
              answer="Você será bem vindo aqui! Nos adoramos ensinar o jogo!"
              question="Eu nunca fiz uma raid, mas gostaria, posso entrar no clã?"
            ></app-question1>
          </div>
        </div>
      </div>
    </div>
    <div class="home-footer">
      <footer class="home-footer1 footerContainer">
        <div class="home-container7">
          <span class="home-logo2 logo">7th</span>
        </div>
        <div class="home-container8">
          <span class="bodySmall home-text26">© 2023 The Seventh Seraph, All Rights Reserved.</span>
          <div class="home-icon-group1"></div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import FeatureCard from '../components/feature-card'
import AppQuestion1 from '../components/question1'

export default {
  name: 'Home',
  props: {},
  components: {
    FeatureCard,
    AppQuestion1,
  },
  data() {
    return {
      showTagline: false,
      // other data properties...
    }
  },
  mounted() {
    setTimeout(() => {
      this.showTagline = true;
    }, 1000); // adjust delay as needed
  },
  metaInfo: {
    title: '[SRPH] 7th Seraph',
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  opacity: 0.75;
  z-index: 100;
  position: fixed;
  align-self: flex-start;
  align-items: stretch;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}

.home-hero-tagline {
  display: inline-block; /* Add this line */
  font-size: 1.5em; /* Adjust as needed */
  font-weight: 300;
  color: white;
  border-right: 0.2em solid;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: typing 1.24s steps(40, end) forwards,
    blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}

.home-navbar-interactive {
  opacity: 0.25;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 0px;
  border-color: var(--dl-color-gray-black);
  border-style: groove;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 0px;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.home-logo {
  color: #ffffff;
}
.home-desktop-menu {
  flex: 1;
  display: none;
  opacity: 0.25;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  margin-right: 32px;
}
.home-burger-menu {
  display: flex;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #141a22;
}
.home-hero1 {
  height: 1086px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: #141a22;
}
.home-video {
  top: 0px;
  flex: 12;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  opacity: 0.45;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
}
.home-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  max-width: 800px;
  align-self: center;
  text-transform: none;
  text-decoration: none;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-hero-button1 {
  text-decoration: none;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-container5 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text22 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text23 {
  text-align: left;
}
.home-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-footer1 {
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-logo2 {
  align-self: flex-start;
}
.home-container8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon-group1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1920px) {
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
}
@media (max-width: 1600px) {
  .home-hero1 {
    height: 913px;
  }
}
@media (max-width: 1200px) {
  .home-hero1 {
    height: 690px;
  }
}
@media (max-width: 991px) {
  .home-hero1 {
    height: 553px;
    flex-direction: column;
  }
  .home-container2 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text23 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media (max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .home-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    height: 435px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container4 {
    grid-template-columns: 1fr;
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container5 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text23 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container8 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text26 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media (max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu1 {
    padding: 16px;
  }
  .home-hero1 {
    height: 273px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-video {
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: auto;
  }
  .home-container2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-container8 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text26 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
